// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-conferences-js": () => import("./../../../src/pages/conferences.js" /* webpackChunkName: "component---src-pages-conferences-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-authors-page-js": () => import("./../../../src/templates/authors-page.js" /* webpackChunkName: "component---src-templates-authors-page-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-conference-js": () => import("./../../../src/templates/conference.js" /* webpackChunkName: "component---src-templates-conference-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-current-authors-page-js": () => import("./../../../src/templates/currentAuthors-page.js" /* webpackChunkName: "component---src-templates-current-authors-page-js" */),
  "component---src-templates-internships-page-js": () => import("./../../../src/templates/internships-page.js" /* webpackChunkName: "component---src-templates-internships-page-js" */),
  "component---src-templates-peer-review-page-js": () => import("./../../../src/templates/peerReview-page.js" /* webpackChunkName: "component---src-templates-peer-review-page-js" */),
  "component---src-templates-series-page-js": () => import("./../../../src/templates/series-page.js" /* webpackChunkName: "component---src-templates-series-page-js" */)
}

